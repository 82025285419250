<template>
  <EntityTable
    :api-url="apiUrl"
    :columns="columns"
    :title="title"
    :edit-page="editPage"
    :delete-url="deleteUrl"
    edit-page-router="mod-request"
  ></EntityTable>
</template>
<script>
import { defineComponent } from "vue";
import EntityTable from "@/components/EntityTable";

export default defineComponent({
  name: "mod-requests",
  components: {
    EntityTable,
  },
  data: function () {
    return {
      apiUrl: "admin/mod-request",
      deleteUrl: "api/admin/mod-request/delete/",

      editPage: "/admin-dashboard/mod-request/",
      title: "Mod requests",
      columns: [
        {
          name: "Id",
          value: "id",
        },
        {
          name: "Date",
          value: "createdDate",
          processor: function (dt) {
            let lang = localStorage.getItem("lang")
                ? localStorage.getItem("lang")
                : "en";

            const formatter = new Intl.DateTimeFormat(lang, {
              day: "numeric",
              month: "long",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
            });
            return formatter.format(new Date(dt));
          },
        },
        {
          name: "Title",
          value: "title",
        },
        {
          name: "Author",
          value: "author",
        },
        {
          name: "IP",
          value: "ipAddress",
        },
      ],
    };
  },

  methods: {},
});
</script>
